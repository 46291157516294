<template>
    <div class="verify_account">
        <div class="area_content">
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <h2>Verify Your Business</h2>
            <h5>Help us make sure you are a real coach who is dedicated to changing lives.</h5>
            <Form class="verify_form" @submit="handleVerify" v-slot="{ errors }">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <!-- <label class="input_label" v-if="form.unsupported_country">Enter your business name and skip to next step.</label> -->
                            <label class="input_label" vv-else>Business Name</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.businessname }">
                                <Field autocomplete="off" name="businessname" type="text" v-model="form.businessname" rules="required" placeholder="Business Name" label="business name" />
                            </div>
                            <ErrorMessage name="businessname" class="text-danger" />
                        </div>
                    </div>
                    <!-- <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Mobile Phone</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.mobile_number }">
                                <Field autocomplete="off" name="mobile_number" type="text" v-model="form.mobile_number" :rules="{ required: form.unsupported_country == false }" placeholder="Mobile Number" label="mobile number">
                                    <vue-tel-input :value="form.mobile_number" @input="handlePhoneNumber" @country-changed="handleCountryChange"></vue-tel-input>
                                </Field>
                            </div>
                            <small v-if="!form.unsupported_country">Phone number with country code ex. +1XXXXXX6789</small><br />
                            <ErrorMessage name="mobile_number" class="text-danger" />
                        </div>
                    </div> -->
                    <div class="create_btn mt-4">
                        <button class="primary_btn" :disabled="accountCreateLoader">
                            <template v-if="accountCreateLoader">
                                <i class="fa fa-spin fa-spinner"></i>&nbsp; {{ form.unsupported_country ? 'Updating' : 'Verifying'}}
                            </template>
                            <template v-else>
                                <span>{{ form.unsupported_country ? 'NEXT STEP' : 'VERIFY'}}</span><i class="fas fa-long-arrow-alt-right suffix"></i>
                            </template>
                        </button>
                    </div>
                    <!-- <p class="skip-business-label mt-3 text-center" @click="handleSkipBusiness()">I'll verify my business later  →</p> -->
                </div>
            </Form>
            <p class="mt-3 text-center"><small>If you are experiencing problems verifying your business just send us an email support@thrivecoach.io</small></p>
        </div>
    </div>
</template>

<script>
    import { VueTelInput } from 'vue3-tel-input'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import 'vue3-tel-input/dist/vue3-tel-input.css'

    export default {
        name: 'Business Verify',

        data () {
            return {
                form: {
                    businessname: '',
                    mobile_number: '',
                    unsupported_country: true,
                },
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
            // VueTelInput,
        },

        computed: mapState({
            accountCreateLoader: state => state.registerModule.accountCreateLoader,
            acountDetails: state => state.registerModule.acountDetails,
        }),

        mounted () {
            const vm = this;

            vm.getAccountDetail(vm.$route.params.token).then((result) => {
                if (!result) {
                    const options = Helper.swalWarningOptions('Oops!', 'The account token is invalid or expired');

                    Swal.fire(options).then((ev) => {
                        if (ev.isConfirmed) {
                            vm.$router.push({ name: 'Register' });
                        }
                    });
                }
            });
        },

        methods: {
            ...mapActions({
                verifyAccountBusiness: 'registerModule/verifyAccountBusiness',
                getAccountDetail: 'registerModule/getAccountDetail',
            }),

            handleVerify (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError = setFieldError;
                params.token = vm.$route.params.token;

                if (vm.$route.query && vm.$route.query.plan) {
                    params.plan = vm.$route.query.plan;
                }

                vm.verifyAccountBusiness(params).then((result) => {
                    if (result) {
                        if (vm.form.unsupported_country) {
                            if (vm.$route.query.plan) {
                                vm.$router.push({name: 'SelectPlan', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                            } else {
                                vm.$router.push({name: 'SelectPlan', params: { token: result.token }});
                            }
                        } else {
                            if (vm.$route.query.plan) {
                                vm.$router.push({name: 'VerifyPhone', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                            } else {
                                vm.$router.push({name: 'VerifyPhone', params: { token: result.token }});
                            }
                        }
                    }
                });
            },

            handlePhoneNumber (phone, phoneObject, input) {
                const vm = this;

                if (phoneObject && phoneObject.number) {
                    vm.form.mobile_number = phoneObject.number
                }
            },

            handleCountryChange (countryObj) {
                const vm = this;
                const availableCountries = ['US', 'UK', 'GB', 'CA', 'AU'];
                const element = document.querySelector('.vue-tel-input input');

                if (availableCountries.includes(countryObj.iso2)) {
                    if (element) {
                        element.disabled = false;
                        element.placeholder = 'Enter a phone number';
                        vm.form.unsupported_country = false;
                    }
                } else {
                    if (element) {
                        element.disabled = true;
                        element.placeholder = 'Unsupported Country please skip';
                        vm.form.unsupported_country = true;
                    }
                }
            },

            handleSkipBusiness () {
                const vm = this;

                if (vm.$route.query.plan) {
                    vm.$router.push({name: 'SelectPlan', params: { token: vm.$route.params.token }, query: { plan: vm.$route.query.plan }});
                } else {
                    vm.$router.push({name: 'SelectPlan', params: { token: vm.$route.params.token }});
                }
            }
        },
    }
</script>

<style scoped>
    .verify_account {
        height: 100vh;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .verify_account .area_content {
        max-width: 500px;
        width: 100%;
        text-align: left;
    }

    .verify_account .area_content .logo {
        margin: 0 0 40px;
    }

    .verify_account .area_content .logo img {
        max-width: 200px;
        height: auto;
        width: 100%;
    }

    .verify_account h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        max-width: 350px;
    }

    .verify_account .verify_form {
        border: 1px solid #e9e9e9;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 20px 40px 45px 40px;
        margin-top: 30px;
    }

    .verify_account .verify_form .field_wpr {
        background: #fff;
    }

    .verify_account .primary_btn {
        position: relative;
        height: 50px;
        cursor: pointer;
    }

    .verify_account .primary_btn span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .verify_account .primary_btn .suffix {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }
    @media(max-width: 991px){
        .verify_account{
            position: relative;
            margin-top: -110px;
        }
    }
    @media(max-width: 767px){
        .verify_account .area_content{
            text-align: center;
        }
        .verify_account h5{
            margin: 0 auto;
        }
    }
    @media(max-width: 499px){
        .verify_account h2{
            font-size: 20px;
        }
        .verify_account .verify_form{
            padding: 20px 30px 40px 30px;
        }
    }

    .skip-business-label {
        font-size: 13px;
        cursor: pointer;
        font-weight: 400;
    }
</style>
